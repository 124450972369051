import instance from "@/api/http";
/**
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} data    请求的参数
 * @param {Object} config  请求的配置
 * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值
 */

const axios = ({
  method,
  url,
  data,
  config
}) => {
  method = method ? method.toLowerCase() : 'get';
  data = {
    ...data,
    // key: '9049EFC2EB0015E23F74F1F5F0F2C443',
    requestFrom: 'H5',
  };
  if (method == 'post') {
    return instance.post(url, data, {...config})
  } else if (method == 'delete') {
    return instance.delete(url, {
      params: data,
      ...config
    })
  } else if (method == 'put') {
    return instance.put(url, data,{...config})
  } else {
    return instance.get(url, {
      params: data,
      ...config
    })
  }
}
export default axios