import axios from "@/api/axios";


export const getGames = data => axios({ url: "/api/games",  data });

export const getManuf = data => axios({ url: "/api/games/manuf",  data });

export const playGame = data => axios({ 
  url: "/api/h5/play",
  method: 'post',
  data,
});
